import React from "react";
import Slider from "../components/home/SliderComp2";
import Navigation from "../components/Layout/Navigation";
import DesktopMenu from "../components/Layout/Navigation/DesktopMenu";
import MenuControls from "../components/Layout/Navigation/MenuControls";
import MobileNavigation from "../components/Layout/Navigation/MobileNavigation";
import useLocalStorage from "../components/useLocalStorage";
import TopBar from "../components/Layout/TopBar";
// import SEO from "../components/seo"
import SEO from "../components/seo";
import Seo from "../components/seo";
import EntryFormButton from "../components/EntryFormButton";
import { css } from "@emotion/core";
import { graphql, Link, navigate, useStaticQuery } from "gatsby";
import tw from "tailwind.macro";
import GatsbyImage from "gatsby-image";
import hero from '../images/comp/prize-img.jpg';
import hero2 from '../images/comp/placeholder.png';
import { Field, Form, Formik, ErrorMessage } from "formik";
import { MdKeyboardArrowRight } from "react-icons/md";
import * as yup from "yup";
import { FaSpinner } from "react-icons/fa";

// import "../scss/main.scss";

const Vote2023 = () => (
    // <Layout>
    <div>
    {/* <SEO title="Dog of the Year 2023 voting" keywords={[`great`, `british`, `dogs`]} /> */}
    <SEO
      title="greatbritishdogs.co.uk | Dog of the Year 2023 voting"
      keywords={[`great`, `british`, `dogs`]}
    />
      <TopBar></TopBar>
      <Navigation>
        <MenuControls />
        <MobileNavigation></MobileNavigation>
      </Navigation>
      <DesktopMenu />

      <Slider />
      <div>
        <div className="container">
          <div
            className="prose max-w-2xl mx-auto py-12"
            css={css`
              h1 {
                ${tw`text-primary font-slab font-bold text-center `}
              }
              h2 {
                ${tw`text-lightCopy font-sans font-bold text-center `}
              }

              .accent {
                ${tw`text-accent`}
              }
            `}
          >
            <h1 css={css`
                display: none !important;
                `}>
              Cast your vote for Dog of the Year 2023
            </h1>
            <h2 css={css`
                margin-top: 0 !important; margin-bottom: 1em !important; font-size: 1.3em !important;text-align: left !important;
                `}>Winalot® needs your help crowning this year’s top dog – choose your favourite now for a chance to win a £100 Amazon voucher</h2>
            <p css={css`
                margin-top: 1.5em !important;
                `}>After much deliberation, a Dog of the Year nominee has been selected from each of the Puppy, Adult, Senior and Small categories on the Great British Dogs website. Now, it’s up to you to decide which of these <a
                href="https://www.greatbritishdogs.co.uk/2023-doty-shortlist"
                className="text-primary font-bold"
                target="_blank"
                css={css`
                color:#ae456c !important;
                text-decoration: underline !important;
                `}
              >
                {" "}four pooches</a> deserves to be this year’s big winner. And when you vote, you’ll automatically be entered into a prize draw to win a £100 Amazon voucher!</p>
            <h3 css={css`
                color: #004030!important; margin: 0.5em auto 1em auto !important; font-size: 1.7rem !important; text-align: center !important; padding: 0 !important;
                `} className="font-slab">Who are the nominees?</h3>
            <p css={css`
                margin-top: 0.75em !important;
                `}>The final four are Bruno (Puppy), Willow (Small), Dora (Adult) and Scout (Senior). If you want to find out more about the four nominees, you can <a
                href="https://www.greatbritishdogs.co.uk/2023-doty-shortlist"
                className="text-primary font-bold"
                target="_blank"
                css={css`
                color:#ae456c !important;
                text-decoration: underline !important;
                `}
              >
                {" "}read their stories here</a>. <strong>Ready to vote? Just scroll down to the bottom of the page.</strong></p>
              <h3 css={css`
                color: #004030!important; margin: 0.5em auto 0.75em auto !important; font-size: 1.7rem !important; text-align: center !important; padding: 0 !important;
                `} className="font-slab">What’s the prize?</h3>
            <p css={css`
                margin-top: 0.75em !important;
                `}>When the Dog of the Year is crowned, their owner will win a beautiful illustration of their furry companion, painted by <a
                href="https://bit.ly/3eY8pB6"
                className="text-primary font-bold"
                target="_blank"
                css={css`
                color:#ae456c !important;
                text-decoration: underline !important;
                `}
              >
                {" "}
                James Bartholomew
              </a>, a talented wildlife artist and the illustrator of Winalot®’s packaging. They’ll also receive a prize hamper for their dog, including Winalot® dog food pouches, a Great British Dog plaque and a dog bed, plus some other goodies.</p><a id="form"></a><br />
          </div>
        </div>
      </div>
      <div className="bg-primary">
        <div className="container" id="vote">
          <div
            className=" max-w-2xl  mx-auto  py-12 ">
            <h3 css={css`
                color: #fff!important; margin: 0.5em auto 0.75em auto !important; font-size: 1.7rem !important; text-align: center !important; padding: 0 !important;
                `} className="font-slab">How to vote</h3>
            <p css={css`
                color: #fff!important; margin: 0.75em auto !important;
                `}>To help choose our Dog of the Year, and maybe even get your hands on a £100 Amazon voucher, simply select your winner from the options below and then enter your details. The vote is open from 1 October 2023 until 31 October 2023 and you can <a
                href="https://www.greatbritishdogs.co.uk/2023-doty-shortlist"
                className="text-primary font-bold"
                target="_blank"
                css={css`
                color:#ffffff !important;
                font-weight: 700 !important;
                text-decoration: underline !important;
                `}
              >
                {" "}read the nominees' stories here</a>...</p>
            {<div className="flex">
                <iframe src="https://doty-voting-form.netlify.app/" width="672" height="1190" class="doty-form-adjust"></iframe>
            </div>}
            <br /><br />
          </div>
        </div>
      </div>

    </div>

// </Layout>
);

export default Vote2023;